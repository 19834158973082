import React from 'react';
import { Card } from 'react-bootstrap';
import { format } from 'date-fns';
import { graphql } from 'gatsby';

import AppConstants from '../app/constants';
import CardTable from '../components/Table/CardTable';
import GlobalActivity from '../components/GlobalActivity/GlobalActivity';
import PageTitle from '../components/layout/PageTitle/PageTitle';
import SEO from '../components/seo';
import WorldMap from '../components/WorldMap';

const globalPopulation = 7794799000;

const CountriesPage = ({ data }) => {
  const [search, setSearch] = React.useState('');
  const [sortBy, setSortBy] = React.useState('cases');

  const {
    allStats: globalCurrentStats,
    allAllStatsDaily: { nodes: globalDailyStats },
    allCountriesStats: { nodes: globalCurrentByCountry}
  } = data;

  const todayData = globalDailyStats[globalDailyStats.length - 1];
  const yesterdayData = globalDailyStats[globalDailyStats.length - 2];

  const globalStats = {
    active: globalCurrentStats.active,
    cases: globalCurrentStats.cases,
    critical: 0,
    deaths: globalCurrentStats.deaths,
    recovered: globalCurrentStats.recovered,
    todayCases: todayData.deltaConfirmed,
    todayDeaths: todayData.deaths.total - yesterdayData.deaths.total,
    casesPerOneMillion: ((globalCurrentStats.cases / globalPopulation) * 1000000).toFixed(2),
    deathsPerOneMillion: ((globalCurrentStats.deaths / globalPopulation) * 1000000).toFixed(2),
  };

  const dailyData = {};

  console.log('globalDailyStats', globalDailyStats);

  globalDailyStats.forEach((item, index) => {
    if (index > 0) {
      const date = format(new Date(item.reportDate), 'yyyy-MM-dd');

      dailyData[date] = {
        dc: item.deaths.total - globalDailyStats[index - 1].deaths.total,
        total: item.deltaConfirmed,
      };
    }
  });

  // Get regions ranges
  const countriesRanges = {
    average: globalStats.cases / Object.keys(globalCurrentByCountry).length,
  };
  countriesRanges.q1 = countriesRanges.average / 2;
  countriesRanges.q3 = countriesRanges.average + (countriesRanges.average / 2);

  const countriesData = globalCurrentByCountry.filter((item) => item.countryInfo && item.countryInfo.iso2).map((item) => {
    let color = AppConstants.MAP_COLORS['0'];

    if (item.cases > 0 && item.cases <= countriesRanges.q1) { color = AppConstants.MAP_COLORS['1']; }
    if (item.cases > countriesRanges.q1 && item.cases <= countriesRanges.average) { color = AppConstants.MAP_COLORS['2']; }
    if (item.cases > countriesRanges.average && item.cases <= countriesRanges.q3) { color = AppConstants.MAP_COLORS['3']; }
    if (item.cases > countriesRanges.q3) { color = AppConstants.MAP_COLORS['4']; }

    return {
      ...item,
      color,
    };
  });

  const globalCurrentData = {
    allDates: globalDailyStats.map((item) => item.reportDate),
    lastDate: globalDailyStats[globalDailyStats.length - 1].reportDate,
    dataByDate: {},
    daily: dailyData,
  }

  globalDailyStats.forEach((item) => {
    globalCurrentData.dataByDate[item.reportDate] = {
      dc: item.deaths.total,
      rad: item.totalRecovered,
      total: item.totalConfirmed, 
    };
  });

  let globalCurrentByCountryToShow = [...countriesData.sort((a, b) => b[sortBy] - a[sortBy])];
  if (search && search.length) {
    globalCurrentByCountryToShow = globalCurrentByCountryToShow
      .filter((item) => item.country.toLowerCase().startsWith(search.toLowerCase()));
  }

  return (
    <>
      <SEO title="Evolution du Coronavirus Covid19 dans le monde" />

      <PageTitle title="Tous les pays" />

      <GlobalActivity
        data={globalCurrentData}
        globalStats={globalStats}
        cumulChartItem={['total', 'dc', 'hosp', 'rea', 'rad']}
        dailyChartItems={['total', 'dc', 'rad']}
      />

      <Card>
        <Card.Body>
          <WorldMap countries={countriesData} />
        </Card.Body>
      </Card>

      <div className="search-bar">
        <input
          type="search"
          className="form-control"
          placeholder="Rechercher un pays"
          onChange={({ target: { value }}) => setSearch(value)}
        />
      </div>

      <CardTable
        headers={['', 'Total', 'Décès', 'Hosp', 'Réa', 'Rétablis', 'Cas/million', 'Décès/million']}
        dataKeys={['country', 'cases', 'deaths', 'active', 'critical', 'recovered', 'casesPerOneMillion', 'deathsPerOneMillion']}
        data={globalCurrentByCountryToShow}
        pathBase={AppConstants.ROUTE_PATHS.countries}
        sortBy={sortBy}
      />
    </>
  );
}

export default CountriesPage;

export const data = graphql`
  query CountriesQuery {
    allStats {
      active
      cases
      deaths
      recovered
      updated
    }
    allCountriesStats {
      nodes {
        active
        cases
        casesPerOneMillion
        country
        countryInfo {
          iso2
        }
        critical
        deaths
        deathsPerOneMillion
        recovered
        todayCases
        todayDeaths
      }
    }
    allAllStatsDaily {
      nodes {
        active
        deaths {
          total
        }
        deltaConfirmed
        deltaRecovered
        deltaConfirmedDetail {
          total
        }
        confirmed {
          total
        }
        recovered {
          total
        }
        reportDate
        totalConfirmed
        totalRecovered
      }
    }
  }
`;
