import PropTypes from 'prop-types';
import React from "react";
import { SvgLoader, SvgProxy } from 'react-svgmt';
import { navigate } from 'gatsby';

import AppConstants from '../../app/constants';
import Map from "./Map";

import './Map.scss';

const WorldMap = ({ color, highlightColor, countries }) => {

  return (
    <SvgLoader svgXML={Map}>
      <SvgProxy
        selector="#world-map"
        fill={color}
      />
      {countries.map((country, countryIndex) => (
        <SvgProxy
          key={countryIndex}
          selector={`#loc-${country.countryInfo.iso2.toLowerCase()}`}
          fill={country.color}
          onClick={(e) => {
            e.preventDefault();
            navigate(`countries/${e.target.id.replace('loc-', '')}`);
          }}
        />
      ))}
    </SvgLoader>
  );
};

WorldMap.propTypes = {
  color: PropTypes.string,
  countries: PropTypes.array,
  highlightColor: PropTypes.string,
};

WorldMap.defaultProps = {
  color: AppConstants.MAP_COLORS['0'],
  highlightColor: "rgba(255,54,0,0.72)",
  countries: [],
};

export default WorldMap;
